import $ from "jquery";

$(function() {
  const copyTextToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => alert("The invite link has been copied to your clipboard."))
      .catch((e) =>
        alert(
          `Something went wrong with copying that link.  Call tech support. ${e}`
        )
      );
  };

  $(".js-copy-to-clipboard[data-value]").each((_idx, el) => {
    const val = $(el).data("value");
    if (val) {
      $(el).on("click", () => copyTextToClipboard(val));
    }
  });
});
