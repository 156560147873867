"use strict";
import { documentReady } from "../document-ready";
import $ from "jquery";

(function() {
  window.LocalTake = window.LocalTake || {};
  window.LocalTake.filterTable = function(filter, elementsToFilter, filterOn) {
    const items = $(elementsToFilter);
    if (!items.length) {
      return;
    }
    if (!filter.length) {
      $(items).prop("hidden", false);
      return;
    }
    const regex = new RegExp(filter, "i");
    $.each(items, function() {
      let $f, matchAgainst;
      const item = this;
      $f = null;
      matchAgainst = (x) => $(x).text();
      if (filterOn && filterOn.data) {
        $f = $(item).find("td[data-filterable-value]");
        matchAgainst = (x) => $(x).data("filterableValue");
      }
      if (!filterOn) {
        $f = $(item);
      }
      if (filterOn && filterOn.selectors) {
        $f = $(item).find(filterOn.selectors.join(","));
      }
      $(item).prop("hidden", !regex.test(matchAgainst($f)));
    });
  };

  $.fn.userTableFilter = function(elementsToFilter, filterOn) {
    this.each(function() {
      $(this).on("keyup", function() {
        const filter = $(this).val();
        window.LocalTake.filterTable(filter, elementsToFilter, filterOn);
      });
    });
  };

  documentReady(function() {
    const $form = $(".js-filter-form");
    if (!$form.length) {
      return;
    }
    const submittable = $form.data("submittable");
    if (!submittable) {
      $form.on("submit", function(ev) {
        return ev.preventDefault();
      });
    }
    const filterableTable = "table.filterable";
    let filters = {
      selectors: ["td.name", "td.email"],
    };

    if ($(filterableTable).data("filterableColumns")) {
      const dataAttr = $(filterableTable)
        .data("filterableColumns")
        .split(/\s+/);
      filters = {
        data: {
          attr: dataAttr,
        },
      };
    }
    const filterableRows = "table.filterable tbody tr";
    $form.find("input#filter").userTableFilter(filterableRows, filters);
    $form.find(".btn.clear-filter").on("click", function() {
      $form.find("input#filter").val("");
      $(filterableRows).prop("hidden", false);
    });
  });
})();
