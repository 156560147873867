import { documentReady } from "../document-ready";
import $ from "jquery";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const formatCurrency = (val) => currencyFormatter.format(val);

const IncomeSources = {
  fetchRollupReports: (tableSelector) => {
    const table = $(tableSelector);
    if (!table.length) {
      return;
    }
    const delay = 200;
    let lastDelay = 0;
    const $total = $(".js-income-sources__rollup-report-cell__total");
    const values = {};
    $(tableSelector)
      .find("tbody tr")
      .each((_index, row) => {
        const cell = $(row).find(".js-fetch-rollup-reports");
        const data = cell.data();
        if (!data.incomeSourceId) {
          return;
        }
        const url = `/admin/income_sources/${data.incomeSourceId}/rollup_report/${data.year}`;
        const fetch = () => {
          return $.ajax({
            url,
            method: "get",
          })
            .then(({ total }) => {
              values[data.year] = total;
              $(row)
                .find(".tiny-spinner")
                .after($("<span>").html(formatCurrency(total)));
              $(row).find(".tiny-spinner").hide();
              $total.html(
                formatCurrency(
                  Object.values(values).reduce((total, val) => total + val, 0)
                )
              );
            })
            .catch((err) => {
              console.error(err);
              $(row)
                .find(".tiny-spinner")
                .after($("<i>", { class: "ion ion-alert-circled" }));
              $(row).find(".tiny-spinner").hide();
            });
        };
        setTimeout(fetch, lastDelay);
        lastDelay += delay;
      });
  },
};

documentReady(() =>
  IncomeSources.fetchRollupReports(".js-fetch-rollup-reports-table")
);
