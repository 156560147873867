import { documentReady } from "../document-ready";
import { Tooltip } from "~bootstrap/";

documentReady(function() {
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll(".tooltipable")
  );
  tooltipTriggerList.map((tooltipTriggerEl) => {
    return new Tooltip(tooltipTriggerEl);
  });
});
