/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";
$(() =>
  // click selects all on feed urls for event types, tour types etc
  $(".selectall").on("click", function() {
    return $(this).select();
  })
);
