/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { documentReady } from "../document-ready";
import { LTSpinner } from "../lt_spinner";
import $ from "jquery";

documentReady(function() {
  const spinner = new LTSpinner();

  const handleOnChange = function() {
    const path = $("#sales_report").val();
    const vendor = $("#income_source").val();
    if (path && vendor) {
      spinner.spin();
      return $("form.income-report").submit();
    }
  };

  return $(".admin-page#income_reports select.fancy").selectize({
    onChange: handleOnChange,
  });
});
