import { documentReady } from "../document-ready";
import { LTSpinner } from "../lt_spinner";
import $ from "jquery";

function initializeSelectizeForSalesReports() {
  const spinner = new LTSpinner();

  const getPath = () => $("#sales-report-chooser").val();

  const handleOnChange = function() {
    const path = getPath();
    const vendor = $("#vendor").val();
    if (path && vendor) {
      spinner.spin();
      const $form = $("form.vendor-chooser");
      $form.attr("action", getPath());
      return $form.submit();
    }
  };

  $(".admin-page#sales_reports select.fancy").selectize({
    onChange: handleOnChange,
  });
}

documentReady(initializeSelectizeForSalesReports);
