import $ from "jquery";
$(function() {
  $(".admin-page#door_counts select.fancy").selectize({
    allowEmptyOption: true,
  });

  const $form = $(".js-door-count-filter-form");
  const $table = $(".js-door-count-table__filterable");
  if (!$form.length || !$table.length) {
    return;
  }

  const isRowFiltered = function($row, filters) {
    const matches = $row
      .find("td[data-filterable-column]")
      .toArray()
      .map(function(cell) {
        const $cell = $(cell);
        const column = $cell.data("filterableColumn");
        const val = $cell.data("filterableValue") || "";
        const filter = filters["filter_by_" + column];
        return filter && filter.toLowerCase() !== (val && val.toLowerCase());
      })
      .filter((val) => Boolean(val) === val);
    return matches.filter((v) => v).length;
  };

  const filterTable = function() {
    const filters = $form
      .find("select")
      .toArray()
      .reduce(function(memo, el) {
        memo[el.name] = memo[el.name] || el.value;
        return memo;
      }, {});
    $table.find("tbody tr").each(function(idx, row) {
      const $row = $(row);
      const hideRow = isRowFiltered($row, filters);
      if (hideRow) {
        $row.attr("hidden", "hidden");
      } else {
        $row.removeAttr("hidden");
      }
    });
  };

  $form.find("select").on("change", filterTable);
  filterTable();
});
