/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { documentReady } from "../document-ready";
import $ from "jquery";

documentReady(function() {
  $(".edit-button").on("click", function(ev) {
    ev.preventDefault();
    $(this).remove();
    $(".editor").toggle();
    $(".preview").toggle();
    if (
      $(".editor").is(":visible") &&
      !$("textarea").closest(".linedtextarea").length
    ) {
      $(".with-number-lines").linedtextarea();
    }

    return false;
  });

  // validate yaml
  return $(".editor a.validate").on("click", function(ev) {
    ev.preventDefault();

    $(".editor").find(".yaml-msg").remove();

    const $contentArea = $(this).closest(".editor").find("textarea");
    const content = $contentArea.val();

    $.ajax({
      url: "/admin/json",
      method: "post",
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
      },
      data: {
        content,
      },
    })
      .done((_resp, _status, _xhr) => {
        return $(".editor").prepend(
          $("<div>", { class: "yaml-msg alert alert-success" }).html(
            "Looks good to me"
          )
        );
      })
      .fail((xhr, _textStatus, _errorThrown) => {
        return $(".editor").prepend(
          $("<div>", { class: "yaml-msg alert alert-danger" }).html(
            xhr.responseText
          )
        );
      });

    return false;
  });
});
